<template>
  
  <!--Contact Form-->
  <div class="timelineblock bg-gray-100 rounded-md p-4 md:p-8 md:pt-6 my-6 h-full">
      <div class="flex flex-wrap gap-x-6">
        <p class="text-4xl font-bold w-full pb-4">Get In Touch</p>
        <div class="w-full flex gap-x-5 flex-col md:flex-row">
          <v-text-field label="Name" class="" v-model="fromName"/>
          <v-text-field label="Email" class="" v-model="fromEmail"/>
        </div>
        <div class="w-full">
          <v-text-field label="Subject" class="" v-model="subject"/>
        </div>
        <v-textarea label="What's on your mind?" class="w-full" v-model="msg"/>
        <v-btn size="large" class="w-full" @click="sendThatShit()">Submit</v-btn>
    </div>
  </div>
  
</template>

<script setup>

const subject = ref('')
const msg = ref('')
const fromEmail = ref('')
const fromName = ref('')

const sendThatShit = async () => {
  const theBody = {
    subject: subject.value,
    msg: msg.value,
    from: fromEmail.value,
    fromName: fromName.value ? fromName.value : 'Anon'
  }
  await $fetch('/api/contact', {method:'POST', body:theBody})
}

</script>